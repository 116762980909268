<template>
  <div  class="body">
    <!-- style="text-align: center;" -->
    

    <el-dialog
      title="提示"
      :visible.sync="resetFormVisible"
      :before-close="handleClose"
    >
      <el-form
        :model="passForm"
        status-icon
        :rules="rules"
        ref="passForm"
        label-width="100px"
      >
        <el-form-item label="旧密码" prop="currentPass">
          <el-input
            type="password"
            v-model="passForm.currentPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input
            type="password"
            v-model="passForm.password"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input
            type="password"
            v-model="passForm.checkPass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('passForm')"
            >提交</el-button
          >
          <el-button @click="resetForm('passForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    

	<div style="width:400px" >
		<h4>你好！{{ form.username }}</h4>
	<!-- <el-divider :content-position="left"> </el-divider> -->
    <table class="u-table" style="table-layout: fixed;" width="100%" cellpadding="0" border="0">
		<tbody style="font-size: 14px;
			font-family: Arial, Helvetica, sans-serif;
			color: #555555;
			">
			<tr v-if="form.id != 3">
				<td style="border-top: 1px dotted #eeeeee;">已开/可开会员数</td>
				<th  style="border-top: 1px dotted #eeeeee;" width="50%">{{form.soldAccountNum}}/{{form.totalAccountNum}}</th>
			</tr>
			<tr  v-if="form.id != 3">
				<td>已开/可开下级代理商数</td>
				<th width="50%">{{form.soldSubNum}}/{{form.totalSubNum}}</th>
			</tr>
			<tr  v-if="form.id != 3">
				<td>剩余时长(分)</td>
				<th width="50%">{{form.rd}}</th>
			</tr>
			<tr>
				<td style="border:0"><a href="#" @click="handleRestPass">重设密码</a></td>
			</tr>
		</tbody>
	  
    </table>
	</div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.passForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      form: {
        organization: "李四物产",
      },
      resetFormVisible: false,
      userInfo: {},
      passForm: {
        password: "",
        checkPass: "",
        currentPass: "",
      },
      rules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 12,
            message: "长度在 6 到 12 个字符",
            trigger: "blur",
          },
        ],
        checkPass: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
        currentPass: [
          { required: true, message: "请输入当前密码", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.getOperator();
  },
  methods: {
	getOperator(){
		this.$axios.get('/sys/user/get/operator').then(res => {
			
			this.form = res.data.data
			console.log("this.form",this.form)
			console.log("res.data.data",res.data.data)
		})
	},
    handleClose() {
      this.resetForm("passForm");
	  this.resetFormVisible = false;
    },
	handleRestPass(){
		this.resetFormVisible = true
	},
    resetForm(formName) {
      this.$refs[formName].resetFields();
      
      this.passForm = {};
    },
    getUserInfo() {
      this.$axios.get("/sys/userInfo").then((res) => {
        this.userInfo = res.data.data;
      });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const _this = this;
          this.$axios
            .post("/sys/user/updatePass", this.passForm)
            .then((res) => {
              _this.$alert(res.data.msg, "提示", {
                confirmButtonText: "确定",
                callback: (action) => {
                  this.$refs[formName].resetFields();
                },
              });

			  this.handleClose()
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form {
  width: 420px;
  margin: 50px auto;
}
/* .u-table td {
  color: #aaa;
} */
th{
	font-weight:light
}
td,  th {
  border-bottom: 1px dotted #eeeeee;
  text-align: left;
  padding: .5em;
  vertical-align: top;
}
th{
	text-align: right;
}

</style>